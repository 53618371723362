import * as React from "react";
import { useIntl } from "gatsby-plugin-intl";

import "../styles/pages/NotFound.scss";

import Layout from "../components/Layout.jsx";
import Seo from "../components/Seo";

const NotFoundPage = () => {
  const intl = useIntl();

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "not-found-title" })} />
      <section className="not-found">
        <h1 className="not-found__title">{intl.formatMessage({ id: "not-found-title" })}</h1>
        <p className="not-found__text">{intl.formatMessage({ id: "not-found-text" })}</p>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
